import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import { useNavigate, useSearchParams } from "react-router-dom";

// Assets
import gidrLogo from "../../assets/gidr-logo.svg";

// Components
import Snackbar from "../../components/Snackbar";

// Icons
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

// Interactions
import { WALLET_API } from "../../lib/axios";

// Translation
import { useTranslation } from "react-i18next";

export default function ResetPassword() {
  const { t } = useTranslation("common");

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [openAlert, setOpenAlert] = useState(false);
  const [dataStatus, setDataStatus] = useState(false);
  const [message, setMessage] = useState("Something went wrong");
  const [showPassword, setShowPassword] = useState(true);
  const [showConfirmPassword, setShowConfirmPassword] = useState(true);
  const [password, setPassword] = useState("");
  const [id, setID] = useState("");
  const [token, setToken] = useState("");
  const [confpass, setConfPass] = useState("");

  useEffect(() => {
    const id = searchParams.get("id");
    const token = searchParams.get("token");
    if (!id || !token) {
      return navigate("/login?msg=invalid");
    }
    WALLET_API.get(`/forgot-password/get?id=${id}&token=${token}`)
      .then(() => {
        setToken(token);
        setID(id);
      })
      .catch(() => {
        return navigate("/login?msg=invalid");
      });
  }, [searchParams, navigate]);

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  const handlePassChange = (event) => {
    setPassword(event.target.value);
  };

  const handleConfChange = (event) => {
    setConfPass(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (password !== confpass) {
      setDataStatus(false);
      setMessage(t("alert.passwordMismatch"));
      setOpenAlert(true);
      setPassword("");
      setConfPass("");
      return;
    }

    const requirements = password.match(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,20}$/
    );
    if (!requirements) {
      setDataStatus(false);
      setMessage(t("alert.passwordRules"));
      setOpenAlert(true);
      setPassword("");
      setConfPass("");
      return;
    }

    WALLET_API.post("/forgot-password/set", {
      id,
      token,
      newpassword: password,
    })
      .then((res) => {
        navigate("/login?msg=reset");
      })
      .catch((err) => {
        setDataStatus(false);
        setMessage(t("alert.passwordInvalid"));
        setOpenAlert(true);
        setPassword("");
        setConfPass("");
      });
  };

  return (
    <Container maxWidth="sm">
      <Snackbar
        isOpen={openAlert}
        isSuccess={dataStatus}
        isClose={handleCloseAlert}
        message={message}
      />
      <Paper sx={{ boxShadow: "rgba(33, 35, 38, 0.1) 0px 10px 10px -10px" }}>
        <form onSubmit={handleSubmit}>
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: 4,
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} sx={{ mb: 1 }}>
                <img src={gidrLogo} width="155px" alt="GIDR Logo" />
              </Grid>
              <Grid item xs={12} md={12} sx={{ mb: 1 }}>
                <Typography variant="h3" align="left">
                  {t("resetPassword.title")}
                </Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography
                  variant="body2"
                  sx={{ mb: "8px", fontWeight: "600" }}
                >
                  {t("resetPassword.passwordLabel")}*
                </Typography>
                <OutlinedInput
                  required
                  fullWidth
                  type={showPassword ? "password" : "text"}
                  placeholder={t("resetPassword.passwordPlaceholder")}
                  inputProps={{
                    style: {
                      fontWeight: 400,
                      fontSize: "14px",
                      height: "15px",
                    },
                  }}
                  name="newpassword"
                  id="newpassword"
                  onChange={handlePassChange}
                  value={password}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography
                  variant="body2"
                  sx={{ mb: "8px", fontWeight: "600" }}
                >
                  {t("resetPassword.confirmPasswordLabel")}*
                </Typography>
                <OutlinedInput
                  required
                  fullWidth
                  type={showConfirmPassword ? "password" : "text"}
                  placeholder={t("resetPassword.confirmPasswordPlaceholder")}
                  inputProps={{
                    style: {
                      fontWeight: 400,
                      fontSize: "14px",
                      height: "15px",
                    },
                  }}
                  name="confpass"
                  id="confpass"
                  onChange={handleConfChange}
                  value={confpass}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowConfirmPassword}
                        edge="end"
                      >
                        {showConfirmPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, color: "#ffffff", boxShadow: "none" }}
            >
              {t("resetPassword.title")}
            </Button>
          </Box>
        </form>
      </Paper>
    </Container>
  );
}
