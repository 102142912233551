import * as React from "react";
import Typography from "@mui/material/Typography";

export default function Title(props) {
  const { header, isBreadcrumbs, subHeader, mainPage, subPage } = props;
  
  return (
    <div style={{ paddingTop: 55 }}>
      <Typography variant="h2" align="left" color="white" gutterBottom>
        {header}
      </Typography>
      {isBreadcrumbs ? (
        <Typography variant="body1" align="left" color="#7e7e7e">
          {mainPage} / {subPage}
        </Typography>
      ) : (
        <Typography variant="body1" align="left" color="#7e7e7e">
          {subHeader}
        </Typography>
      )}
    </div>
  );
}
