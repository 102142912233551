import * as React from "react";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Link, useNavigate } from "react-router-dom";

// Assets
import gidrLogo from "../../assets/gidr-logo.svg";

// Interactions
import { useState } from "react";
import { WALLET_API } from "../../lib/axios";

// Translation
import { useTranslation } from "react-i18next";

export default function ForgotPassword() {
  const { t } = useTranslation("common");
  
  const navigate = useNavigate();

  const [email, setEmail] = useState("");

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleClick = (e) => {
    WALLET_API.post("/forgot-password/req", {
      email,
    })
      .then((res) => res.data.data)
      .then((res) => {
        navigate("/login?msg=forgot");
      })
      .catch((err) => alert(`Fail result: ${err}`));
  };

  return (
    <Container maxWidth="sm">
      <Paper sx={{ boxShadow: "rgba(33, 35, 38, 0.1) 0px 10px 10px -10px" }}>
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: 4,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} sx={{ mb: 1 }}>
              <img src={gidrLogo} width="155px" alt="GIDR Logo" />
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography variant="h3" align="left">
                {t("forgotPassword.title")}
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              md={6}
              sx={{ alignContent: "center", textAlign: "right" }}
            >
              <Link
                to="/login"
                style={{
                  textDecoration: "unset",
                  fontSize: "14px",
                  color: "#E3AD1A",
                }}
              >
                {t("forgotPassword.login")}
              </Link>
            </Grid>
            <Grid item xs={12} md={12} sx={{ mt: 1 }}>
              <Typography variant="body2" align="left">
                {t("forgotPassword.description")}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                required
                fullWidth
                type="email"
                placeholder={t("forgotPassword.placeholder")}
                inputProps={{
                  style: { fontWeight: 400, fontSize: "14px", height: "15px" },
                }}
                autoFocus
                name="email"
                id="email"
                value={email}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, color: "#ffffff", boxShadow: "none" }}
            onClick={handleClick}
          >
            {t("forgotPassword.submit")}
          </Button>
        </Box>
      </Paper>
    </Container>
  );
}
