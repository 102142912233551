import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";

// Icons
import WalletIcon from "@mui/icons-material/Wallet";

export default function HistoryCard({ ...props }) {
  return (
    <Card variant="outlined" sx={{ border: "unset" }}>
      <CardContent
        sx={{
          padding: 2,
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={6} md={6} sx={{ alignContent: "center" }}>
            <Typography variant="body2" sx={{ fontWeight: 600 }} gutterBottom>
              {props.title}
            </Typography>
          </Grid>
          <Grid item xs={6} md={6} sx={{ textAlign: "-webkit-right" }}>
            <Avatar sx={{ backgroundColor: "#141517" }}>
              <WalletIcon sx={{ color: "#fff" }} />
            </Avatar>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ pt: 3 }}>
          <Grid item xs={12} md={12}>
            <Typography variant="h1">{props.amount}</Typography>
            <Typography variant="body2" color="#7e7e7e">
              {props.description}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
