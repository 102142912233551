import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";

// Translation
import { useTranslation } from "react-i18next";

export default function TransactionHistory({ ...props }) {
  const { t } = useTranslation("common");

  const { isOpen, isClose, data } = props;

  const width = "xs";

  const filterStatus = (data) => {
    switch (data) {
      case "SUCCESS":
        return (
          <Typography variant="body2" fontWeight="600" color="#2e7d32">
            {t("transaction.status.success")}
          </Typography>
        );
      case "IN_PROGRESS":
        return (
          <Typography variant="body2" fontWeight="600" color="#ed6c02">
            {t("transaction.status.pending")}
          </Typography>
        );
      default:
        return (
          <Typography variant="body2" fontWeight="600" color="#d32f2f">
            {t("transaction.status.failed")}
          </Typography>
        );
    }
  };

  return (
    <Dialog fullWidth open={isOpen} onClose={isClose} maxWidth={width}>
      <DialogTitle>
        <Typography variant="h4" fontWeight="600">
          {t("transaction.modal.title")}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Typography variant="body2" color="#7e7e7e" gutterBottom>
              {t("transaction.modal.transactionHash")}
            </Typography>
            <Typography
              variant="body2"
              fontWeight="600"
              sx={{ wordWrap: "break-word" }}
            >
              <a
                href={"https://polygonscan.com/tx/" + data.txhash}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  textDecoration: "none",
                  color: "#E3AD1A",
                }}
              >
                {data.txhash ? data.txhash : "N/A"}
              </a>
            </Typography>
          </Grid>
          <Grid item xs={6} md={6}>
            <Typography variant="body2" color="#7e7e7e" gutterBottom>
              {t("transaction.modal.date")}
            </Typography>
            <Typography variant="body2" fontWeight="600">
              {new Date(data.date).toLocaleDateString()}
            </Typography>
          </Grid>
          <Grid item xs={6} md={6}>
            <Typography variant="body2" color="#7e7e7e" gutterBottom>
              {t("transaction.modal.status")}
            </Typography>
            {filterStatus(data.status)}
          </Grid>
          <Grid item xs={12} md={12}>
            <Typography variant="body2" color="#7e7e7e" gutterBottom>
              {t("transaction.modal.from")}
            </Typography>
            <Typography
              variant="body2"
              fontWeight="600"
              sx={{ wordWrap: "break-word" }}
            >
              {data.from}
            </Typography>
          </Grid>
          <Grid item xs={12} md={12}>
            <Typography variant="body2" color="#7e7e7e" gutterBottom>
              {t("transaction.modal.to")}
            </Typography>
            <Typography
              variant="body2"
              fontWeight="600"
              sx={{ wordWrap: "break-word" }}
            >
              {data.to}
            </Typography>
          </Grid>
          <Grid item xs={12} md={12}>
            <Typography variant="body2" color="#7e7e7e" gutterBottom>
              {t("transaction.modal.amount")}
            </Typography>
            <Typography variant="body2" fontWeight="600">
              {data.amount} GIDR
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={isClose}>{t("transaction.modal.close")}</Button>
      </DialogActions>
    </Dialog>
  );
}
