import * as React from "react";
import Alert from "@mui/material/Alert";

export default function ResponsiveAlert({ ...props }) {
  const { description } = props;

  return (
    <Alert variant="filled" severity="error">
      {description}
    </Alert>
  );
}
