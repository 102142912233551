import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";

// Assets
import cardBg from "../../assets/card.png";

// Icons
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";

// Translation
import { useTranslation } from "react-i18next";

export default function PersonalInformationCard({ ...props }) {
  const { t } = useTranslation("common");

  const { email, verified } = props;

  return (
    <Card variant="outlined" sx={{ border: "unset" }}>
      <CardContent
        sx={{
          backgroundImage: `url(${cardBg})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundColor: "unset",
          padding: 2,
        }}
      >
        <Grid container spacing={2} sx={{ pb: 1.1 }}>
          <Grid item xs={6} md={6} sx={{ alignContent: "center" }}>
            <Typography variant="body2" sx={{ fontWeight: 600 }} gutterBottom>
              {t("accountInformation.title")}
            </Typography>
          </Grid>
          <Grid item xs={6} md={6} sx={{ textAlign: "-webkit-right" }}>
            <Avatar sx={{ backgroundColor: "#141517" }}>
              <PersonOutlineIcon sx={{ color: "#E3AD1A" }} />
            </Avatar>
          </Grid>
        </Grid>
        <Typography variant="body2" color="#4d4d4d" gutterBottom>
          {t("accountInformation.referralCode")}
        </Typography>
        <Typography variant="h2">-</Typography>
        <Grid
          container
          direction="row"
          justifyContent="left"
          spacing={4}
          sx={{ pt: 1 }}
        >
          <Grid item>
            <Typography variant="body2" color="#4d4d4d" gutterBottom>
              {t("accountInformation.emailAddress")}
            </Typography>
            <Typography variant="body2" fontWeight="600">
              {email}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" color="#4d4d4d" gutterBottom>
              {t("accountInformation.kycStatus")}
            </Typography>
            <Typography
              variant="body2"
              fontWeight="600"
              color={verified ? "#2e7d32" : "#d32f2f"}
            >
              {verified ? t("kycStatus.verified") : t("kycStatus.notVerified")}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
