import React, { Fragment, useState } from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

// Components
import TopUpDialog from "../dialog/TopUp";

// Assets
import topupImg from "../../assets/topup.svg";

// Translation
import { useTranslation } from "react-i18next";

export default function TopupInfoCard() {
  const { t } = useTranslation("common");

  const [openTopUpDialog, setOpenTopUpDialog] = useState(false);

  const handleOpenTopUpDialog = () => {
    setOpenTopUpDialog(true);
  };
  const handleCloseTopUpDialog = () => {
    setOpenTopUpDialog(false);
  };

  return (
    <Fragment>
      <TopUpDialog isOpen={openTopUpDialog} isClose={handleCloseTopUpDialog} />
      <Card
        sx={{
          p: 2,
          background: "linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%)",
          boxShadow: "unset",
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={6} md={6}>
            <div style={{ position: "relative", height: "100%" }}>
              <div style={{ marginBottom: "15px" }}>
                <Typography variant="h4" sx={{ fontWeight: 600 }}>
                  {t("topupGIDR.title")}
                </Typography>
              </div>
              <div>
                <Typography variant="body2">
                  {t("topupGIDR.description")}
                </Typography>
              </div>
              <div style={{ position: "absolute", bottom: 0 }}>
                <Button
                  sx={{
                    pl: 0,
                    "&:hover": {
                      background: "unset",
                      fontWeight: "600",
                    },
                  }}
                  onClick={handleOpenTopUpDialog}
                  variant="text"
                >
                  {t("topupGIDR.button")}
                </Button>
              </div>
            </div>
          </Grid>
          <Grid item xs={6} md={6} sx={{ textAlign: "right" }}>
            <img src={topupImg} width="195px" alt="GIDR - Topup" />
          </Grid>
        </Grid>
      </Card>
    </Fragment>
  );
}
