import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Chip from "@mui/material/Chip";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

// Assets
import redeemedImg from "../../assets/redeem.svg";

// Translation
import { useTranslation } from "react-i18next";

function RedemptionStepper() {
  const { t } = useTranslation("common");

  return (
    <Box sx={{ width: "100%" }}>
      <Grid container spacing={1}>
        <Grid item xs={12} md={12} sx={{ textAlign: "center", mb: 1 }}>
          <img src={redeemedImg} width="220px" alt="GIDR - Redeemed" />
        </Grid>
        <Grid item xs={12} md={12} sx={{ mb: 1 }}>
          <Typography variant="body1" fontWeight="600">
            {t("redeemGIDR.modal.description")}
          </Typography>
        </Grid>
        <Grid item xs={12} md={12}>
          <Stepper
            sx={{
              "& .MuiStepConnector-line": {
                minHeight: "15px",
              },
            }}
            orientation="vertical"
          >
            <Step active>
              <StepLabel>{t("redeemGIDR.modal.step1")}</StepLabel>
            </Step>
            <Step active>
              <StepLabel>{t("redeemGIDR.modal.step2")}</StepLabel>
            </Step>
            <Step active>
              <StepLabel>{t("redeemGIDR.modal.step3")}</StepLabel>
            </Step>
            <Step active>
              <StepLabel>{t("redeemGIDR.modal.step4")}</StepLabel>
            </Step>
            <Step active>
              <StepLabel>{t("redeemGIDR.modal.step5")}</StepLabel>
            </Step>
          </Stepper>
        </Grid>
      </Grid>
    </Box>
  );
}

export default function RedemptionDialog({ ...props }) {
  const { t } = useTranslation("common");

  const { isOpen, isClose, profile } = props;

  const [redemptionAmount, setRedemptionAmount] = useState("");

  const handleChangeRedemptionAmount = (e) => {
    setRedemptionAmount(e.target.value);
  };

  return (
    <Dialog
      fullWidth
      open={isOpen}
      onClose={isClose}
      maxWidth={"sm"}
      disableEscapeKeyDown
    >
      <DialogTitle>
        <Typography variant="h4" fontWeight="600">
          {t("redeemGIDR.modal.title")}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1} sx={{ mt: 2 }}>
          <Grid item xs={12} md={12} sx={{ mb: 2 }}>
            <RedemptionStepper />
          </Grid>
          <Grid item xs={12} md={12} sx={{ mb: 1 }}>
            <Typography variant="body1" fontWeight="600">
              {t("redeemGIDR.modal.accountInformation.title")}
            </Typography>
          </Grid>
          <Grid item xs={12} md={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <Typography variant="body2" fontWeight="600" sx={{ mb: "8px" }}>
                  {t("redeemGIDR.modal.accountInformation.walletAddress")}
                </Typography>
                <Typography variant="body2" color="#7e7e7e">
                  {!profile.walletaddress ? "-" : profile.walletaddress}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  variant="body2"
                  sx={{ mb: "8px", fontWeight: "600" }}
                >
                  {t("redeemGIDR.modal.accountInformation.nik")}
                </Typography>
                <Typography variant="body2" color="#7e7e7e">
                  {!profile.nik ? "-" : profile.nik}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  variant="body2"
                  sx={{ mb: "8px", fontWeight: "600" }}
                >
                  {t("redeemGIDR.modal.accountInformation.fullname")}
                </Typography>
                <Typography variant="body2" color="#7e7e7e">
                  {!profile.fullname ? "-" : profile.fullname}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  variant="body2"
                  sx={{ mb: "8px", fontWeight: "600" }}
                >
                  {t("redeemGIDR.modal.accountInformation.emailAddress")}
                </Typography>
                <Typography variant="body2" color="#7e7e7e">
                  {!profile.email ? "-" : profile.email}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  variant="body2"
                  sx={{ mb: "8px", fontWeight: "600" }}
                >
                  {t("redeemGIDR.modal.accountInformation.kycStatus")}
                </Typography>
                <Chip
                  label={
                    profile.statusemail !== "UNVERIFIED"
                      ? t("kycStatus.verified")
                      : t("kycStatus.notVerified")
                  }
                  color={
                    profile.statusemail !== "UNVERIFIED" ? "success" : "error"
                  }
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography
                  variant="body2"
                  sx={{ mb: "8px", fontWeight: "600" }}
                >
                  {t("redeemGIDR.modal.accountInformation.redemptionAmount")}
                </Typography>
                <FormControl fullWidth>
                  <Select
                    value={redemptionAmount}
                    fullWidth
                    label={null}
                    onChange={handleChangeRedemptionAmount}
                    style={{ height: "50px", fontSize: "14px" }}
                  >
                    <MenuItem value="">
                      <em>
                        {t(
                          "redeemGIDR.modal.accountInformation.redemptionAmountPlaceholder"
                        )}
                      </em>
                    </MenuItem>
                    <MenuItem value={"5"}>5 GIDR</MenuItem>
                    <MenuItem value={"10"}>10 GIDR</MenuItem>
                    <MenuItem value={"25"}>25 GIDR</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12} sx={{ mt: 2 }}>
                <Button
                  variant="contained"
                  disabled={
                    profile.statusemail === "UNVERIFIED" ||
                    redemptionAmount === ""
                  }
                  sx={{ fontWeight: "600", boxShadow: "unset" }}
                  fullWidth
                >
                  {t("redeemGIDR.modal.accountInformation.button")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={isClose}>{t("redeemGIDR.modal.close")}</Button>
      </DialogActions>
    </Dialog>
  );
}
