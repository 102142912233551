import React, { Fragment, useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { useNavigate } from "react-router-dom";

// Assets
import headerBg from "../../assets/header.png";

// Components
import AppBar from "../../components/AppBar";
import Title from "../../components/Title";
import HistoryCard from "../../components/card/History";
import Withdraw from "./Withdraw";
import Deposit from "./Deposit";

// API
import { WALLET_API } from "../../lib/axios";

// Translation
import { useTranslation } from "react-i18next";

export default function TransactionHistory() {
  const { t } = useTranslation("common");

  const navigate = useNavigate();

  const [tabsValue, setTabsValue] = useState("1");
  const [data, setData] = useState({
    debit: [],
    credit: [],
  });

  const auth = localStorage.getItem("auth");

  useEffect(() => {
    if (auth == null) {
      navigate("/login");
    } else {
      WALLET_API.get("/account/wallet/transaction", {
        headers: {
          Authorization: `Bearer ${auth}`,
        },
      })
        .then((res) => res.data.data)
        .then((res) => {
          setData(
            res ?? {
              debit: [],
              credit: [],
            }
          );
        })
        .catch(() => {
          localStorage.removeItem("auth");
          navigate("/login");
        });
    }
  }, [auth, navigate]);

  const handleChangeTab = (event, newValue) => {
    setTabsValue(newValue);
  };

  const filterSuccessTransaction = (array) => {
    const filteredData = array.filter((x) => x.status === "SUCCESS");
    return filteredData.length;
  };

  return (
    <Fragment>
      <Box
        sx={{
          backgroundImage: `url(${headerBg})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
          height: "350px",
          width: "100vw",
          position: "absolute",
          top: 0,
          left: 0,
          opacity: 1,
          borderRadius: "unset",
          boxShadow: "unset",
        }}
      />
      <AppBar />
      <Container maxWidth="lg" sx={{ p: 1 }}>
        <div style={{ position: "relative" }}>
          <Title
            header={t("navigation.transactionHistory")}
            isBreadcrumbs={true}
            mainPage={t("navigation.dashboard")}
            subPage={t("navigation.transactionHistory")}
          />
        </div>
        <div style={{ position: "relative" }}>
          <Grid
            container
            spacing={2}
            sx={{ position: "absolute", pt: 4, left: 0, right: 0 }}
          >
            <Grid item xs={12} md={6}>
              <HistoryCard
                title={t("transaction.depositHeader")}
                amount={filterSuccessTransaction(data.credit)}
                description={t("transaction.depositSubHeader")}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <HistoryCard
                title={t("transaction.withdrawHeader")}
                amount={filterSuccessTransaction(data.debit)}
                description={t("transaction.withdrawSubHeader")}
              />
            </Grid>
            <Grid item xs={12} md={12} sx={{ mt: 2, pb: "120px" }}>
              <Paper
                sx={{
                  p: 4,
                  boxShadow: "rgba(33, 35, 38, 0.1) 0px 10px 10px -10px",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <TabContext value={tabsValue}>
                      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <TabList onChange={handleChangeTab}>
                          <Tab label={t("transaction.deposit")} value="1" />
                          <Tab label={t("transaction.withdraw")} value="2" />
                        </TabList>
                      </Box>
                      <TabPanel sx={{ p: 1 }} value="1">
                        <Deposit data={data.credit} />
                      </TabPanel>
                      <TabPanel sx={{ p: 1 }} value="2">
                        <Withdraw data={data.debit} />
                      </TabPanel>
                    </TabContext>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </div>
      </Container>
    </Fragment>
  );
}
