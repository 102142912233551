import * as React from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Link } from "react-router-dom";

// Assets
import notFoundImg from "../../assets/404.svg";

export default function NotFound() {
  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          marginTop: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: 2,
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <img
              src={notFoundImg}
              alt="GIDR Logo"
              style={{ width: "-webkit-fill-available" }}
            />
          </Grid>
          <Grid item xs={12} md={12} sx={{ mb: 1 }}>
            <Typography variant="h3" align="center">
              Oops, Page Not Found 🧐
            </Typography>
          </Grid>
        </Grid>
        <Button
          fullWidth
          component={Link}
          to="/"
          variant="contained"
          sx={{ mt: 3, color: "#ffffff", boxShadow: "none" }}
        >
          Back to Home
        </Button>
      </Box>
    </Container>
  );
}
