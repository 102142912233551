import React, { Fragment, useState, useEffect } from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

// Components
import RedemptionDialog from "../dialog/Redemption";

// Assets
import redeemedImg from "../../assets/redeem.svg";

// API
import { CMS_API } from "../../lib/axios";

// Translation
import { useTranslation } from "react-i18next";

export default function RedeemInfoCard({ ...props }) {
  const { t } = useTranslation("common");

  const { verified, balance, profile } = props;

  const [redeemLimit, setRedeemLimit] = useState(0);
  const [openRedemptionDialog, setOpenRedemptionDialog] = useState(false);

  const navigate = useNavigate();
  const auth = localStorage.getItem("auth");

  const handleOpenRedemptionDialog = () => {
    setOpenRedemptionDialog(true);
  };
  const handleCloseRedemptionDialog = () => {
    setOpenRedemptionDialog(false);
  };

  useEffect(() => {
    let fetchData;
    if (auth == null) {
      navigate("/login");
    } else {
      CMS_API.get("v1/redeemlimit/get")
        .then((res) => {
          fetchData = res.data.response;
          setRedeemLimit(fetchData?.data[0]?.gidrlimit);
        })
        .catch(() => {
          setRedeemLimit(5);
        });
    }
  }, [auth, navigate]);

  return (
    <Fragment>
      <RedemptionDialog
        isOpen={openRedemptionDialog}
        isClose={handleCloseRedemptionDialog}
        profile={profile}
      />
      <Card
        sx={{
          p: 2,
          background: "linear-gradient(120deg, #ebedee 0%, #fdfbfb 100%)",
          boxShadow: "unset",
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={6} md={6}>
            <div style={{ position: "relative", height: "100%" }}>
              <div style={{ marginBottom: "15px" }}>
                <Typography variant="h4" sx={{ fontWeight: 600 }}>
                  {t("redeemGIDR.title")}
                </Typography>
              </div>
              <div>
                <Typography variant="body2">
                  {t("redeemGIDR.description")}
                </Typography>
              </div>
              <div style={{ position: "absolute", bottom: 0 }}>
                <Button
                  disabled={!verified || balance <= redeemLimit ? true : false}
                  onClick={handleOpenRedemptionDialog}
                  sx={{
                    pl: 0,
                    "&:hover": {
                      background: "unset",
                      fontWeight: "600",
                    },
                  }}
                  variant="text"
                >
                  {t("redeemGIDR.button")}
                </Button>
              </div>
            </div>
          </Grid>
          <Grid item xs={6} md={6} sx={{ textAlign: "right" }}>
            <img src={redeemedImg} width="195px" alt="GIDR - Redeemed" />
          </Grid>
        </Grid>
      </Card>
    </Fragment>
  );
}
