import React, { Fragment, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Container from "@mui/material/Container";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";

// Date Picker
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import "dayjs/locale/en-gb";

// Phone Input
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

// Assets
import headerBg from "../assets/header.png";

// Components
import AppBar from "../components/AppBar";
import Title from "../components/Title";
import Snackbar from "../components/Snackbar";

// Icons
import CopyAll from "@mui/icons-material/CopyAll";

// API
import { WALLET_API } from "../lib/axios";
import provinceData from "../data/province.json";

// Translation
import { useTranslation } from "react-i18next";

export default function Profile() {
  const { t } = useTranslation("common");

  const navigate = useNavigate();
  const defaultform = {
    fullname: "",
    phonenumber: 0,
    nik: "",
    gender: "",
    province: "",
    address: "",
    email: "",
    dob: dayjs(null),
  };

  const [openAlert, setOpenAlert] = useState(false);
  const [dataStatus, setDataStatus] = useState(false);
  const [message, setMessage] = useState("Something went wrong");
  const [statusEmail, setSEmail] = useState(false);
  // const [statusKYC, setSKYC] = useState(false);
  const [nikError, setNErr] = useState(false);
  const [nikHelp, setNHelp] = useState("");
  const [formData, setFormData] = useState(defaultform);
  const [email, setEmail] = useState("Loading...");
  const [wallet, setWallet] = useState("Loading...");

  const auth = localStorage.getItem("auth");

  useEffect(() => {
    if (auth == null) {
      navigate("/login");
    } else {
      WALLET_API.get("/account/profile/get", {
        headers: {
          Authorization: `Bearer ${auth}`,
        },
      })
        .then((res) => res.data.data)
        .then((res) => {
          setFormData({
            fullname: res.fullname ?? "",
            phonenumber: res.phonenumber ?? "",
            nik: res.nik ?? "",
            dob: dayjs(res.dob),
            address: res.address ?? "",
            province: res.province,
            gender:
              res.gender === "MALE"
                ? "Male"
                : res.gender === "FEMALE"
                ? "Female"
                : "",
          });
          setEmail(res.email ?? "N/A");
          setWallet(res.walletaddress ?? "N/A");
          setSEmail(res.statusemail === "VERIFIED");
          // setSKYC(res.statuskyc === "VERIFIED")
        })
        .catch(() => {
          localStorage.removeItem("auth");
          navigate("/login");
        });
    }
  }, [auth, navigate]);

  const handleChange = (event) => {
    if (event && event.target) {
      setFormData({ ...formData, [event.target.name]: event.target.value });

      if (event.target.name === "nik") {
        const numOnly = new RegExp("[0-9]+");
        const nik = event.target.value;
        if (nik.length === 16 && numOnly.test(nik)) {
          setNErr(false);
          setNHelp("");
        } else {
          setNErr(true);
          setNHelp("Invalid NIK");
        }
      }
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    WALLET_API.post(
      "/account/profile/set",
      {
        ...formData,
        dob: formData.dob.toISOString(),
      },
      {
        headers: {
          Authorization: `Bearer ${auth}`,
        },
      }
    )
      .then((res) => res.data.data)
      .then((res) => {
        setMessage(t("alert.profileUpdateSuccess"));
        setOpenAlert(true);
        setDataStatus(true);
      })
      .catch(() => {
        setMessage(t("alert.profileUpdateFailed"));
        setOpenAlert(true);
        setDataStatus(false);
      });
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  return (
    <Fragment>
      <Snackbar
        isOpen={openAlert}
        isSuccess={dataStatus}
        isClose={handleCloseAlert}
        message={message}
      />
      <Box
        sx={{
          backgroundImage: `url(${headerBg})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
          height: "350px",
          width: "100vw",
          position: "absolute",
          top: 0,
          left: 0,
          opacity: 1,
          borderRadius: "unset",
          boxShadow: "unset",
        }}
      />
      <AppBar />
      <Container maxWidth="lg" sx={{ p: 1 }}>
        <div style={{ position: "relative" }}>
          <Title
            header={t("navigation.profile")}
            isBreadcrumbs={true}
            mainPage={t("navigation.dashboard")}
            subPage={t("navigation.profile")}
          />
        </div>
        <div style={{ position: "relative" }}>
          <Grid
            container
            spacing={2}
            sx={{ position: "absolute", pt: 4, left: 0, right: 0 }}
          >
            <Grid item xs={12} md={12} sx={{ mt: 2, pb: "120px" }}>
              <Paper
                sx={{
                  p: 4,
                  boxShadow: "rgba(33, 35, 38, 0.1) 0px 10px 10px -10px",
                }}
              >
                <form id="profileForm" onSubmit={handleSubmit}>
                  <Grid container spacing={2} sx={{ pb: 4 }}>
                    <Grid item xs={12} md={12} sx={{ pb: 2 }}>
                      <Grid container spacing={2}>
                        <Grid
                          item
                          xs={12}
                          md={6}
                          sx={{ alignContent: "center" }}
                        >
                          <Typography
                            variant="body1"
                            fontWeight="600"
                            align="left"
                          >
                            {t("profile.title")}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Stack
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                            spacing={1}
                          >
                            <Chip
                              label={
                                statusEmail
                                  ? t("kycStatus.verified")
                                  : t("kycStatus.notVerified")
                              }
                              color={statusEmail ? "success" : "error"}
                            />
                            {/* <Chip label={statusKYC ? "EKYC Complete" : "EKYC Not Complete"} color={statusKYC ? "success" : "error"} /> */}
                          </Stack>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography
                        variant="body2"
                        color="#7e7e7e"
                        sx={{ mb: "8px", fontWeight: "600" }}
                      >
                        {t("profile.walletIdLabel")}
                      </Typography>
                      <Stack direction={"row"} alignItems={"center"}>
                        <Typography variant="body2" fontWeight="600">
                          {wallet}
                        </Typography>
                        <Tooltip
                          title="Copy Address"
                          onClick={() => {
                            navigator.clipboard.writeText(wallet);
                          }}
                        >
                          <IconButton
                            sx={{ width: 20, height: 20, ml: "15px" }}
                          >
                            <CopyAll />
                          </IconButton>
                        </Tooltip>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography
                        variant="body2"
                        color="#7e7e7e"
                        sx={{ mb: "8px", fontWeight: "600" }}
                      >
                        {t("profile.referralCode")}
                      </Typography>
                      <Typography variant="body2" fontWeight="600">
                        -
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Typography
                        variant="body2"
                        color="#7e7e7e"
                        sx={{ mb: "8px", fontWeight: "600" }}
                      >
                        {t("profile.nikLabel")}
                      </Typography>
                      <TextField
                        required
                        fullWidth
                        placeholder={t("profile.nikPlaceholder")}
                        inputProps={{
                          style: {
                            fontWeight: 400,
                            fontSize: "14px",
                            height: "15px",
                          },
                        }}
                        name="nik"
                        id="nik"
                        onChange={handleChange}
                        value={formData.nik}
                        error={nikError}
                        helperText={nikHelp}
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Typography
                        variant="body2"
                        color="#7e7e7e"
                        sx={{ mb: "8px", fontWeight: "600" }}
                      >
                        {t("profile.fullnameLabel")}
                      </Typography>
                      <TextField
                        required
                        fullWidth
                        placeholder={t("profile.fullnamePlaceholder")}
                        inputProps={{
                          style: {
                            fontWeight: 400,
                            fontSize: "14px",
                            height: "15px",
                          },
                        }}
                        value={formData.fullname}
                        id="fullname"
                        name="fullname"
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography
                        variant="body2"
                        color="#7e7e7e"
                        sx={{ mb: "8px", fontWeight: "600" }}
                      >
                        {t("profile.genderLabel")}
                      </Typography>
                      <FormControl fullWidth>
                        <Select
                          value={formData.gender || ""}
                          fullWidth
                          label={null}
                          name="gender"
                          id="gender"
                          onChange={handleChange}
                          style={{ height: "50px", fontSize: "14px" }}
                        >
                          <MenuItem value="">
                            <em>{t("profile.genderPlaceholder")}</em>
                          </MenuItem>
                          <MenuItem value={"Male"}>Male</MenuItem>
                          <MenuItem value={"Female"}>Female</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography
                        variant="body2"
                        color="#7e7e7e"
                        sx={{ mb: "8px", fontWeight: "600" }}
                      >
                        {t("profile.dobLabel")}
                      </Typography>
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale={"en-gb"}
                      >
                        <DatePicker
                          onChange={(newdob) =>
                            setFormData({
                              ...formData,
                              dob: newdob,
                            })
                          }
                          name="dob"
                          id="dob"
                          value={formData.dob || ""}
                          slotProps={{
                            textField: {
                              fullWidth: true,
                            },
                          }}
                          sx={{
                            "& .MuiInputBase-root": {
                              height: "50px",
                              fontSize: "14px",
                            },
                          }}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography
                        variant="body2"
                        color="#7e7e7e"
                        sx={{ mb: "8px", fontWeight: "600" }}
                      >
                        {t("profile.addressLabel")}
                      </Typography>
                      <TextField
                        required
                        fullWidth
                        placeholder={t("profile.addressPlaceHolder")}
                        name="address"
                        id="address"
                        onChange={handleChange}
                        value={formData.address}
                        inputProps={{
                          style: {
                            fontWeight: 400,
                            fontSize: "14px",
                            height: "15px",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography
                        variant="body2"
                        color="#7e7e7e"
                        sx={{ mb: "8px", fontWeight: "600" }}
                      >
                        {t("profile.provinceLabel")}
                      </Typography>
                      <FormControl fullWidth>
                        <Select
                          value={formData.province || ""}
                          name="province"
                          id="province"
                          fullWidth
                          label={null}
                          onChange={handleChange}
                          style={{ height: "48px", fontSize: "14px" }}
                        >
                          {provinceData.map((x) => {
                            return (
                              <MenuItem key={x.id} value={x.alt_name}>
                                {x.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    {/* Contact */}
                    <Grid item xs={12} md={12} sx={{ mt: 2, mb: 1 }}>
                      <Typography variant="body1" fontWeight="600" align="left">
                        {t("profile.contact")}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography
                        variant="body2"
                        color="#7e7e7e"
                        sx={{ mb: "8px", fontWeight: "600" }}
                      >
                        {t("profile.emailAddressLabel")}
                      </Typography>
                      <TextField
                        required
                        fullWidth
                        disabled
                        placeholder={t("profile.emailAddressPlaceholder")}
                        inputProps={{
                          style: {
                            fontWeight: 400,
                            fontSize: "14px",
                            height: "15px",
                          },
                        }}
                        value={email}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography
                        variant="body2"
                        color="#7e7e7e"
                        sx={{ mb: "8px", fontWeight: "600" }}
                      >
                        {t("profile.phoneNumberLabel")}
                      </Typography>
                      <PhoneInput
                        country={"id"}
                        value={formData.phonenumber || 0}
                        inputStyle={{
                          height: "48px",
                          width: "100%",
                          paddingLeft: "45px",
                        }}
                        inputProps={{
                          name: "phonenumber",
                          id: "phonenumber",
                          onChange: handleChange,
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justifyContent="right"
                    spacing={2}
                  >
                    <Grid item>
                      <Button variant="text" type="submit">
                        {t("profile.update")}
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="text"
                        sx={{ color: "#141517" }}
                        onClick={() => setFormData(defaultform)}
                      >
                        {t("profile.reset")}
                      </Button>
                    </Grid>
                  </Grid>
                </form>
                {/* <Grid  container spacing={2} sx={{ pb: 4 }}>
                  <Grid item xs={12} md={12} sx={{ mt: 2, pb: 2 }}>
                    <Typography variant="body1" fontWeight="600" align="left">
                      Security Setting
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography
                      variant="body2"
                      sx={{ mb: "8px", fontWeight: "600" }}
                    >
                      New Password*
                    </Typography>
                    <OutlinedInput
                      required
                      fullWidth
                      type={showPassword ? "password" : "text"}
                      placeholder="Input Your Password"
                      inputProps={{
                        style: {
                          fontWeight: 400,
                          fontSize: "14px",
                          height: "15px",
                        },
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography
                      variant="body2"
                      sx={{ mb: "8px", fontWeight: "600" }}
                    >
                      Confirm New Password*
                    </Typography>
                    <OutlinedInput
                      required
                      fullWidth
                      type={showConfirmPassword ? "password" : "text"}
                      placeholder="Input Your Confirm New Password"
                      inputProps={{
                        style: {
                          fontWeight: 400,
                          fontSize: "14px",
                          height: "15px",
                        },
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowConfirmPassword}
                            edge="end"
                          >
                            {showConfirmPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction="row"
                  justifyContent="right"
                  spacing={2}
                >
                  <Grid item>
                    <Button variant="text" type="submit">Update Password</Button>
                  </Grid>
                  <Grid item>
                    <Button variant="text" sx={{ color: "#141517" }} onClick={() => {
                      //TODO
                    }}>
                      Reset
                    </Button>
                  </Grid>
                </Grid> */}
              </Paper>
            </Grid>
          </Grid>
        </div>
      </Container>
    </Fragment>
  );
}
