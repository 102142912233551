import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormControl from "@mui/material/FormControl";
import { Link, useSearchParams } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

// Assets
import gidrLogo from "../../assets/gidr-logo.svg";

// Components
import Snackbar from "../../components/Snackbar";
import Timer from "../../components/Timer";

// Icons
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

// Interactions
import { useNavigate } from "react-router-dom";
import { WALLET_API } from "../../lib/axios";

// Translation
import { useTranslation } from "react-i18next";

export default function SignIn() {
  const { t, i18n } = useTranslation("common");

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const formDefault = { password: "", email: "" };

  const [language, setLanguage] = useState("en");
  const [openAlert, setOpenAlert] = useState(false);
  const [dataStatus, setDataStatus] = useState(false);
  const [otpStatus, setOtpStatus] = useState(false);
  const [otp, setOtp] = useState("");
  const [tempAuth, setTempAuth] = useState(null);
  const [message, setMessage] = useState(t("alert.general"));
  const [formData, setFormData] = useState(formDefault);
  const [showPassword, setShowPassword] = useState(true);

  useEffect(() => {
    const msg = searchParams.get("msg");
    if (msg === "registered") {
      setMessage(t("alert.registered"));
      setDataStatus(true);
      setOpenAlert(true);
    }
    if (msg === "forgot") {
      setMessage(t("alert.forgotPassword"));
      setDataStatus(true);
      setOpenAlert(true);
    }
    if (msg === "reset") {
      setMessage(t("alert.resetPassword"));
      setDataStatus(true);
      setOpenAlert(true);
    }
    if (msg === "invalid") {
      setMessage(t("alert.invalidURL"));
      setDataStatus(false);
      setOpenAlert(true);
    }
  }, [searchParams, t]);

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    WALLET_API.post("/login", formData)
      .then((res) => res.data.data)
      .then((res) => {
        setTempAuth(res.auth);
        setOtpStatus(true);
      })
      .catch(() => {
        setMessage(t("alert.signIn"));
        setDataStatus(false);
        setOpenAlert(true);
        setFormData(formDefault);
      });
  };

  const handleOtp = (e) => {
    e.preventDefault();
    WALLET_API.post(
      "/login/verify",
      {
        otp,
      },
      {
        headers: {
          Authorization: `Bearer ${tempAuth}`,
        },
      }
    )
      .then((res) => res.data.data)
      .then((res) => {
        localStorage.setItem("auth", res.auth);
        navigate("/");
      })
      .catch(() => {
        setMessage(t("alert.otp"));
        setDataStatus(false);
        setOpenAlert(true);
        setOtp("");
      });
  };

  const handleChangeLanguage = (event) => {
    setLanguage(event.target.value);
    i18n.changeLanguage(event.target.value);
  };

  return (
    <Container maxWidth="sm">
      <Snackbar
        isOpen={openAlert}
        isSuccess={dataStatus}
        isClose={handleCloseAlert}
        message={message}
      />
      <Paper sx={{ boxShadow: "rgba(33, 35, 38, 0.1) 0px 10px 10px -10px" }}>
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: 4,
          }}
        >
          {otpStatus ? (
            <Grid container spacing={2}>
              <Grid item xs={6} md={6}>
                <img src={gidrLogo} width="155px" alt="GIDR Logo" />
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography variant="h3" align="left">
                  {t("otp.title")}
                </Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography variant="body2">{t("otp.description")}</Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <Timer
                  initialDuration={10 * 60 * 1000}
                  onExpire={() => {
                    setOtpStatus(false);
                    setOtp("");
                  }}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <form onSubmit={handleOtp}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      <TextField
                        required
                        fullWidth
                        type="text"
                        placeholder={t("otp.placeholder")}
                        name="otp"
                        inputProps={{
                          style: { fontWeight: 400, fontSize: "14px" },
                        }}
                        autoFocus
                        onChange={(e) => {
                          setOtp(e.target.value);
                        }}
                        value={otp}
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Button
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, color: "#ffffff", boxShadow: "none" }}
                        type="submit"
                      >
                        {t("otp.verify")}
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </Grid>
            </Grid>
          ) : (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={6} md={6}>
                  <img src={gidrLogo} width="155px" alt="GIDR Logo" />
                </Grid>
                <Grid item xs={6} md={6} sx={{ textAlign: "right" }}>
                  <FormControl variant="standard" sx={{ m: 1 }}>
                    <Select
                      value={language}
                      disableUnderline
                      onChange={handleChangeLanguage}
                      sx={{ fontSize: "14px", fontWeight: 600 }}
                      displayEmpty
                    >
                      <MenuItem value={"en"} sx={{ fontSize: "14px" }}>
                        EN
                      </MenuItem>
                      <MenuItem value={"id"} sx={{ fontSize: "14px" }}>
                        ID
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Typography variant="h3" align="left">
                    {t("signIn.title")}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={6}
                  sx={{ alignContent: "center", textAlign: "right" }}
                >
                  <Link
                    to="/register"
                    style={{
                      textDecoration: "unset",
                      fontSize: "14px",
                      color: "#E3AD1A",
                    }}
                  >
                    {t("signIn.register")}
                  </Link>
                </Grid>
                <Grid item xs={12} md={12} sx={{ mt: 1 }}>
                  <Typography
                    variant="body2"
                    sx={{ mb: "8px", fontWeight: "600" }}
                  >
                    {t("signIn.emailAddressLabel")}
                  </Typography>
                  <TextField
                    required
                    fullWidth
                    type="email"
                    placeholder={t("signIn.emailAddressPlaceholder")}
                    name="email"
                    inputProps={{
                      style: { fontWeight: 400, fontSize: "14px" },
                    }}
                    autoFocus
                    onChange={handleChange}
                    value={formData.email}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <Typography
                    variant="body2"
                    sx={{ mb: "8px", fontWeight: "600" }}
                  >
                    {t("signIn.passwordLabel")}
                  </Typography>
                  <OutlinedInput
                    required
                    fullWidth
                    type={showPassword ? "password" : "text"}
                    placeholder={t("signIn.passwordPlaceholder")}
                    name="password"
                    onChange={handleChange}
                    value={formData.password}
                    inputProps={{
                      style: { fontWeight: 400, fontSize: "14px" },
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  sx={{ alignContent: "center", textAlign: "right", mb: 2 }}
                >
                  <Link
                    to="/forgotpassword"
                    style={{
                      textDecoration: "unset",
                      fontSize: "14px",
                      color: "#E3AD1A",
                    }}
                  >
                    {t("signIn.forgotPassword")}
                  </Link>
                </Grid>
              </Grid>
              <Button
                fullWidth
                variant="contained"
                sx={{ mt: 3, color: "#ffffff", boxShadow: "none" }}
                type="submit"
              >
                {t("signIn.title")}
              </Button>
            </form>
          )}
        </Box>
      </Paper>
    </Container>
  );
}
