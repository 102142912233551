import axios from "axios";

const WALLET_API = axios.create({
  baseURL: process.env.REACT_APP_WALLET_URL,
  timeout: "3000",
});

const CMS_API = axios.create({
  baseURL: process.env.REACT_APP_CMS_URL,
  timeout: "1000",
});

export { WALLET_API, CMS_API };