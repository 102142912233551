import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

// Assets
import topupImg from "../../assets/topup.svg";

// Translation
import { useTranslation } from "react-i18next";

function TopUpStepper() {
  const { t } = useTranslation("common");

  return (
    <Box sx={{ width: "100%" }}>
      <Grid container spacing={1}>
        <Grid item xs={12} md={12} sx={{ textAlign: "center", mb: 1 }}>
          <img src={topupImg} width="220px" alt="GIDR - Top Up" />
        </Grid>
        <Grid item xs={12} md={12} sx={{ mb: 1 }}>
          <Typography variant="body1" fontWeight="600">
            {t("topupGIDR.modal.description")}
          </Typography>
        </Grid>
        <Grid item xs={12} md={12}>
          <Stepper
            sx={{
              "& .MuiStepConnector-line": {
                minHeight: "15px",
              },
            }}
            orientation="vertical"
          >
            <Step active>
              <StepLabel>
                {t("topupGIDR.modal.step1")}{" "}
                <a
                  style={{
                    fontWeight: "600",
                    textDecoration: "none",
                    color: "#E3AD1A",
                  }}
                  href="https://market.gudangkripto.id/market/gidr?p=idr"
                  target="_blank"
                  rel="noopener, noreferrer"
                >
                  {t("topupGIDR.modal.subStep1")}
                </a>
              </StepLabel>
            </Step>
            <Step active>
              <StepLabel>{t("topupGIDR.modal.step2")}</StepLabel>
            </Step>
            <Step active>
              <StepLabel>{t("topupGIDR.modal.step3")}</StepLabel>
            </Step>
            <Step active>
              <StepLabel>{t("topupGIDR.modal.step4")}</StepLabel>
            </Step>
            <Step active>
              <StepLabel>{t("topupGIDR.modal.step5")}</StepLabel>
            </Step>
            <Step active>
              <StepLabel>{t("topupGIDR.modal.step6")}</StepLabel>
              <Grid container spacing={1} sx={{ pl: 4 }}>
                <Grid item xs={12}>
                  <Typography variant="body2" gutterBottom>
                    {t("topupGIDR.modal.subStep6a")}
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    {t("topupGIDR.modal.subStep6b")}
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    {t("topupGIDR.modal.subStep6c")}
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    {t("topupGIDR.modal.subStep6d")}
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    {t("topupGIDR.modal.subStep6e")}
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    {t("topupGIDR.modal.subStep6f")}
                  </Typography>
                </Grid>
              </Grid>
            </Step>
            <Step active>
              <StepLabel>{t("topupGIDR.modal.step7")}</StepLabel>
            </Step>
            <Step active>
              <StepLabel>{t("topupGIDR.modal.step8")}</StepLabel>
            </Step>
          </Stepper>
        </Grid>
      </Grid>
    </Box>
  );
}

export default function TopUpDialog({ ...props }) {
  const { t } = useTranslation("common");

  const { isOpen, isClose } = props;

  return (
    <Dialog fullWidth open={isOpen} onClose={isClose} maxWidth={"sm"}>
      <DialogTitle>
        <Typography variant="h4" fontWeight="600">
          {t("topupGIDR.modal.title")}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1} sx={{ mt: 2 }}>
          <Grid item xs={12} md={12} sx={{ mb: 2 }}>
            <TopUpStepper />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={isClose}> {t("topupGIDR.modal.close")}</Button>
      </DialogActions>
    </Dialog>
  );
}
