import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import { IconButton, Tooltip } from "@mui/material";

// Assets
import cardBg from "../../assets/card.png";

// Icons
import WalletIcon from "@mui/icons-material/Wallet";
import CopyAll from "@mui/icons-material/CopyAll";

// Translation
import { useTranslation } from "react-i18next";

function truncateString(str, maxLength, ellipsis = "...") {
  if (str.length <= maxLength) {
    return str;
  }

  return str.substring(0, maxLength - ellipsis.length) + ellipsis;
}

export default function BalanceCard({ ...props }) {
  const { t } = useTranslation("common");

  const { balance, wallet, price } = props;

  return (
    <Card variant="outlined" sx={{ border: "unset" }}>
      <CardContent
        sx={{
          backgroundImage: `url(${cardBg})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundColor: "unset",
          padding: 2,
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={6} md={6} sx={{ alignContent: "center" }}>
            <Typography variant="body2" sx={{ fontWeight: 600 }} gutterBottom>
              {t("accountBalance.title")}
            </Typography>
          </Grid>
          <Grid item xs={6} md={6} sx={{ textAlign: "-webkit-right" }}>
            <Avatar sx={{ backgroundColor: "#141517" }}>
              <WalletIcon sx={{ color: "#E3AD1A" }} />
            </Avatar>
          </Grid>
        </Grid>
        <Typography variant="h1" sx={{ pt: 3, pb: 1 }}>
          {balance} GIDR
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={7}>
            <Typography variant="body2" color="#4d4d4d" gutterBottom>
              {t("accountBalance.walletID")}
            </Typography>
            <Typography variant="body2" fontWeight="600">
              {truncateString(wallet, 20)}
              <Tooltip
                title="Copy Address"
                onClick={() => {
                  navigator.clipboard.writeText(wallet);
                }}
              >
                <IconButton sx={{ width: 18, height: 18, ml: "5px" }}>
                  <CopyAll />
                </IconButton>
              </Tooltip>
            </Typography>
          </Grid>
          <Grid item xs={12} md={5}>
            <Typography variant="body2" color="#4d4d4d" gutterBottom>
              {t("accountBalance.idrEstimation")}
            </Typography>
            <Typography variant="body2" fontWeight="600">
              Rp. {(price * balance).toLocaleString()},-
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
