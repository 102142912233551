import * as React from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

export default function SnackbarAlert({ ...props }) {
  const { isOpen, isSuccess, isClose, message } = props;

  const vertical = "bottom";
  const horizontal = "right";

  return (
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      open={isOpen}
      autoHideDuration={6000}
      onClose={isClose}
    >
      {isSuccess ? (
        <Alert
          onClose={isClose}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      ) : (
        <Alert
          onClose={isClose}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      )}
    </Snackbar>
  );
}
