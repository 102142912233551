import React, { useState, useEffect, useRef } from "react";
import { Typography } from "@mui/material";

function Timer({ initialDuration = 0, onExpire }) {
  const intervalRef = useRef(null);

  const [finishTime] = useState(Date.now() + initialDuration);
  const [remainingTime, setRemainingTime] = useState(initialDuration);

  useEffect(() => {
    const startTimer = () => {
      if (remainingTime > 0) {
        setRemainingTime(finishTime - Date.now()); // Update every second
      } else {
        stopTimer();
        onExpire && onExpire(); // Call optional callback on expiration
      }
    };

    intervalRef.current = setInterval(startTimer, 1000);

    return () => clearInterval(intervalRef.current); // Cleanup on unmount
  }, [remainingTime, finishTime, onExpire]); // Dependency array optimization

  const stopTimer = () => {
    clearInterval(intervalRef.current);
  };

  const formatTime = (time) => {
    const hours = Math.floor(time / (1000 * 60 * 60));
    const minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((time % (1000 * 60)) / 1000);

    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };

  return (
    <Typography
      variant="h3"
      align="left"
      color={remainingTime > 60 * 1000 ? "black" : "red"}
    >
      {formatTime(remainingTime)}
    </Typography>
  );
}

export default Timer;
