import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

// Components
import Footer from "./components/Footer";

// Auth - Pages
import SignIn from "./pages/auth/SignIn";
import SignUp from "./pages/auth/SignUp";
import ForgotPassword from "./pages/auth/ForgotPassword";
import ResetPassword from "./pages/auth/ResetPassword";

// Others - Pages
import NotFound from "./pages/others/404";
import SuccessReset from "./pages/others/SuccessReset";

// Pages
import Dashboard from "./pages/Dashboard";
import SuccessVerify from "./pages/others/SuccessVerify";
import Profile from "./pages/Profile";

// Transaction
import TransactionIndex from "./pages/transactions/Index";

const theme = createTheme({
  palette: {
    background: {
      default: "#FAFAFA",
    },
    primary: {
      main: "#E3AD1A",
    },
  },
  typography: {
    fontFamily: `"Manrope", sans-serif`,
    letterSpacing: "-0.5px",
    h1: {
      fontWeight: 600,
      fontSize: "32px",
      lineHeight: "48px",
    },
    h2: {
      fontWeight: 600,
      fontSize: "24px",
      lineHeight: "36px",
    },
    h3: {
      fontWeight: 600,
      fontSize: "20px",
      lineHeight: "30px",
    },
    h4: {
      fontWeight: 600,
      fontSize: "18px",
      lineHeight: "28px",
    },
    body1: {
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "24px",
    },
    body2: {
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "22px",
    },
    caption: {
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "18px",
    },
    button: {
      textTransform: "none",
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route path="/login" element={<SignIn />} exact></Route>
          <Route path="/register" element={<SignUp />} exact></Route>
          <Route
            path="/forgotpassword"
            element={<ForgotPassword />}
            exact
          ></Route>
          <Route path="/" element={<Dashboard />} exact></Route>
          <Route
            path="/resetpassword"
            element={<ResetPassword />}
            exact
          ></Route>
          <Route
            path="/verificationregister"
            element={<SuccessVerify />}
            exact
          ></Route>
          <Route path="/successReset" element={<SuccessReset />} exact></Route>
          <Route path="/dashboard" element={<Dashboard />} exact></Route>
          <Route path="/profile" element={<Profile />} exact></Route>
          <Route path="/history" element={<TransactionIndex />} exact></Route>
          <Route path="*" element={<NotFound />}></Route>
        </Routes>
      </Router>
      <Footer />
    </ThemeProvider>
  );
}

export default App;
