import React, { Fragment, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";

// Icons
import InfoIcon from "@mui/icons-material/Info";

// Components
import HistoryDetailDialog from "../dialog/History";

// Translation
import { useTranslation } from "react-i18next";

function truncateString(str, maxLength, ending = "...") {
  // Check if string length is less than or equal to max
  if (str.length <= maxLength) return str;

  // Truncate string to maxLength and add ending
  return str.slice(0, maxLength - ending.length) + ending;
}

export default function TransactionHistoryTable({ ...props }) {
  const { t } = useTranslation("common");

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openDetailDialog, setOpenDetailDialog] = useState(false);
  const { data, showOnProcess, type } = props;
  const [selectedData, setSelectedData] = useState([]);

  const columns = [
    { id: "id", label: t("transaction.table.no") },
    {
      id: "transactionHash",
      label: t("transaction.table.transactionHash"),
      minWidth: 100,
    },
    type === "credit"
      ? { id: "from", label: t("transaction.table.from") }
      : { id: "to", label: t("transaction.table.to") },
    { id: "amount", label: t("transaction.table.amount"), align: "right" },
    { id: "status", label: t("transaction.table.status"), align: "right" },
    { id: "date", label: t("transaction.table.date"), align: "left" },
    { id: "action", label: t("transaction.table.detail"), align: "center" },
  ];

  const handleCloseDetailDialog = () => {
    setOpenDetailDialog(false);
  };

  const rows = data
    .filter((v) => showOnProcess || v.status !== "IN_PROGRESS")
    .map((v, i) => ({
      id: i + 1,
      transactionHash: v.txhash ? truncateString(v.txhash, 20) : "N/A",
      from: v.from,
      to: v.to,
      amount: `${v.amount} GIDR`,
      status:
        v.status === "IN_PROGRESS" ? (
          <Chip label="On Process" color="warning" />
        ) : v.status === "SUCCESS" ? (
          <Chip label="Success" color="success" />
        ) : (
          <Chip label="Failed" color="error" />
        ),
      date: new Date(v.date).toLocaleDateString(),
      action: (
        <IconButton
          onClick={() => {
            setSelectedData(v);
            setOpenDetailDialog(true);
          }}
        >
          <InfoIcon />
        </IconButton>
      ),
    }));

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Fragment>
      <HistoryDetailDialog
        isOpen={openDetailDialog}
        isClose={handleCloseDetailDialog}
        data={selectedData}
      />
      <Paper
        sx={{
          width: "100%",
          overflow: "hidden",
          boxShadow: "unset",
        }}
      >
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      minWidth: column.minWidth,
                      backgroundColor: "#E3AD1A",
                      color: "#ffffff",
                      fontWeight: 700,
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format && typeof value === "number"
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Fragment>
  );
}
